import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

function useImagesQuery() {
  const data = useStaticQuery(graphql`
    query {
      saasBG: file(relativePath: { eq: "SaaS-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      newSaasBG: file(relativePath: { eq: "new-saas-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      saasDesktop0: file(relativePath: { eq: "saas-desktop0.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      saasDesktop1: file(relativePath: { eq: "saas-desktop1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      saasDesktop2: file(relativePath: { eq: "saas-desktop2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      saasDesktop3: file(relativePath: { eq: "saas-desktop3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      saasDesktop4: file(relativePath: { eq: "saas-desktop4.png" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      saasDesktop5: file(relativePath: { eq: "saas-desktop5.png" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      saasTablet0: file(relativePath: { eq: "saas-tablet0.png" }) {
        childImageSharp {
          fluid(maxWidth: 1076, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      saasTablet1: file(relativePath: { eq: "saas-tablet1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1076, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      saasTablet2: file(relativePath: { eq: "saas-tablet2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1076, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      saasMobile0: file(relativePath: { eq: "saas-mobile0.png" }) {
        childImageSharp {
          fluid(maxWidth: 560, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      saasMobile1: file(relativePath: { eq: "saas-mobile1.png" }) {
        childImageSharp {
          fluid(maxWidth: 560, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      saasMobile2: file(relativePath: { eq: "saas-mobile2.png" }) {
        childImageSharp {
          fluid(maxWidth: 560, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      saasMobile3: file(relativePath: { eq: "saas-mobile3.png" }) {
        childImageSharp {
          fluid(maxWidth: 560, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      saasMobile4: file(relativePath: { eq: "saas-mobile4.png" }) {
        childImageSharp {
          fluid(maxWidth: 560, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      saasMobile5: file(relativePath: { eq: "saas-mobile5.png" }) {
        childImageSharp {
          fluid(maxWidth: 560, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      spaceBackgroundImage: file(relativePath: { eq: "space-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      dsstBg: file(relativePath: { eq: "dsst-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      softwareDevelopmentBg: file(relativePath: { eq: "software-development-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      astraload: file(relativePath: { eq: "astraload.png" }) {
        childImageSharp {
          fluid(maxWidth: 90, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      dashboardDesktop: file(relativePath: { eq: "dashboard-desktop.png" }) {
        childImageSharp {
          fluid(maxWidth: 827, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      dashboardTablet: file(relativePath: { eq: "dashboard-tablet.png" }) {
        childImageSharp {
          fluid(maxWidth: 615, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      dashboardMobile: file(relativePath: { eq: "dashboard-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 291, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      techGC: file(relativePath: { eq: "techgc.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1100, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      knotel: file(relativePath: { eq: "knotel.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1100, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      knotable: file(relativePath: { eq: "knotable.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1100, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      selfleaders: file(relativePath: { eq: "selfleaders.png" }) {
        childImageSharp {
          fixed(width: 120, height: 120) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      rabbit: file(relativePath: { eq: "rabbit.png" }) {
        childImageSharp {
          fixed(width: 120, height: 120) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      tgc: file(relativePath: { eq: "tgc.png" }) {
        childImageSharp {
          fixed(width: 120, height: 120) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      domenic: file(relativePath: { eq: "domenic.png" }) {
        childImageSharp {
          fixed(width: 265, height: 177) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      tool1: file(relativePath: { eq: "tool-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 810, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      tool2: file(relativePath: { eq: "tool-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 810, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      tool3: file(relativePath: { eq: "tool-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 810, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      tool4: file(relativePath: { eq: "tool-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 810, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      tool5: file(relativePath: { eq: "tool-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 810, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      tool6: file(relativePath: { eq: "tool-6.png" }) {
        childImageSharp {
          fluid(maxWidth: 810, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      tool7: file(relativePath: { eq: "tool-7.png" }) {
        childImageSharp {
          fluid(maxWidth: 810, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      tool8: file(relativePath: { eq: "tool-8.png" }) {
        childImageSharp {
          fluid(maxWidth: 810, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      tool9: file(relativePath: { eq: "tool-9.png" }) {
        childImageSharp {
          fluid(maxWidth: 810, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      tool10: file(relativePath: { eq: "tool-10.png" }) {
        childImageSharp {
          fluid(maxWidth: 810, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      tool11: file(relativePath: { eq: "tool-11.png" }) {
        childImageSharp {
          fluid(maxWidth: 810, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      tool12: file(relativePath: { eq: "tool-12.png" }) {
        childImageSharp {
          fluid(maxWidth: 810, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      figma: file(relativePath: { eq: "figma.png" }) {
        childImageSharp {
          fluid(maxWidth: 572, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      storybook: file(relativePath: { eq: "storybook.png" }) {
        childImageSharp {
          fluid(maxWidth: 754, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      benefit1: file(relativePath: { eq: "benefit-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      benefit2: file(relativePath: { eq: "benefit-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      benefit3: file(relativePath: { eq: "benefit-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      benefit4: file(relativePath: { eq: "benefit-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      earthSvg: file(relativePath: { eq: "earth.svg" }) {
        extension
        publicURL
      }
      moonSvg: file(relativePath: { eq: "moon.svg" }) {
        extension
        publicURL
      }

      astronaut: file(relativePath: { eq: "astronaut.svg" }) {
        extension
        publicURL
      }

      effortsStepsBg: file(relativePath: { eq: "efforts-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }

      presentationContactsBg: file(
        relativePath: { eq: "presentation-contacts-bg.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }

      presentationPhasesBg: file(
        relativePath: { eq: "presentation-phases-bg.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      figmaLogo: file(
        relativePath: { eq: "figma-logo.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      storybookLogo: file(
        relativePath: { eq: "storybook-logo.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      presentationMobilePreview: file(
        relativePath: { eq: "presentation-mobile-preview.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      questAppImg: file(
        relativePath: { eq: "quest-app-img.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      astraloadAppImg: file(
        relativePath: { eq: "astraload-app-img.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      screenCaptureChromeExtension: file(
        relativePath: { eq: "maestroQAChromeExtension.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techNode: file(
        relativePath: { eq: "technologies/node.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techReact: file(
        relativePath: { eq: "technologies/react.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techJS: file(
        relativePath: { eq: "technologies/js.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techTS: file(
        relativePath: { eq: "technologies/ts.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techSQL: file(
        relativePath: { eq: "technologies/sql.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techMongo: file(
        relativePath: { eq: "technologies/mongo.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techUnion: file(
        relativePath: { eq: "technologies/union.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techFigma: file(
        relativePath: { eq: "technologies/figma.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techPS: file(
        relativePath: { eq: "technologies/ps.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techAI: file(
        relativePath: { eq: "technologies/ai.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techWebflow: file(
        relativePath: { eq: "technologies/webflow.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techAws: file(
        relativePath: { eq: "technologies/aws.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techGithub: file(
        relativePath: { eq: "technologies/github.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techCircleCI: file(
        relativePath: { eq: "technologies/circleci.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techLinux: file(
        relativePath: { eq: "technologies/linux.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techDocker: file(
        relativePath: { eq: "technologies/docker.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techHetzner: file(
        relativePath: { eq: "technologies/hetzner.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techJira: file(
        relativePath: { eq: "technologies/jira.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 760, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techIcon1: file(
        relativePath: { eq: "technologies/icon1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techIcon2: file(
        relativePath: { eq: "technologies/icon2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techGA: file(
        relativePath: { eq: "technologies/google-analytics.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techHotjar: file(
        relativePath: { eq: "technologies/hotjar.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      techMixpanel: file(
        relativePath: { eq: "technologies/mixpanel.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      servicesFeedback1: file(relativePath: { eq: "services-client-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      
      servicesFeedback2: file(relativePath: { eq: "services-client-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      
      servicesFeedback3: file(relativePath: { eq: "services-client-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      
      servicesBlogPreview1: file(relativePath: { eq: "services-blog-preview-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 740, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      
      servicesBlogPreview2: file(relativePath: { eq: "services-blog-preview-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 740, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      
      servicesBlogPreview3: file(relativePath: { eq: "services-blog-preview-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 740, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      
      clientFeedbackLogo1: file(relativePath: { eq: "client-maestroqa.png" }) {
        childImageSharp {
          fluid(maxWidth: 305, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      
      clientFeedbackLogo2: file(relativePath: { eq: "client-selfleaders.png" }) {
        childImageSharp {
          fluid(maxWidth: 362, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      
      advantagesImg: file(relativePath: { eq: "advantages-img.png" }) {
        childImageSharp {
          fluid(maxWidth: 1328, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      
      differentsImg: file(relativePath: { eq: "differents-img.png" }) {
        childImageSharp {
          fluid(maxWidth: 1140, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      
      appSupportImg: file(relativePath: { eq: "app-support-img.png" }) {
        childImageSharp {
          fluid(maxWidth: 1140, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      
      intelligentAssistantImg: file(relativePath: { eq: "intelligent-assistant-img.png" }) {
        childImageSharp {
          fluid(maxWidth: 1140, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      
      evgenyImg: file(relativePath: { eq: "evgeny.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return data
}

function GatsbyImage({
  alt,
  name,
  width = "",
  customStyle = {},
  customClassName = "",
}) {
  const image = useImagesQuery()

  const style = width ? { style: { width, ...customStyle } } : {}

  return (
    <Img
      fluid={image[name].childImageSharp.fluid}
      alt={alt}
      objectFit="cover"
      className={customClassName}
      {...style}
    />
  )
}

function GatsbyImageFixed({ alt, name, className = null }) {
  const image = useImagesQuery()
  return (
    <Img
      className={className}
      fixed={image[name].childImageSharp.fixed}
      alt={alt}
      objectFit="cover"
      objectPosition="50% 50%"
    />
  )
}

function GatsbyImageBackground({ alt, name = "", children, className }) {
  const image = useImagesQuery()

  return (
    <BackgroundImage
      className={className}
      fluid={image[name].childImageSharp.fluid}
      alt={alt}
      fadeIn={false}
      critical={false}
    >
      {children}
    </BackgroundImage>
  )
}

function useGatsbyImagePath(name, type) {
  const image = useImagesQuery()
  return image[name].childImageSharp[type].src
}

function usePublicUrl(name) {
  const images = useImagesQuery()
  return images && images[name] && images[name].publicURL
}

function useFluidUrl(name) {
  const image = useImagesQuery()
  return image[name].childImageSharp.fluid
}

export {
  GatsbyImage,
  GatsbyImageFixed,
  GatsbyImageBackground,
  useGatsbyImagePath,
  usePublicUrl,
  useFluidUrl
}
